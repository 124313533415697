  
import '@babel/polyfill'
import 'core-js/stable';
import 'regenerator-runtime/runtime';

import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import axios from 'axios'
import Cookies from 'js-cookie'
import qs from 'qs';
import JSONBig from "json-bigint";
import 'amfe-flexible'
import Vant from 'vant'
import 'vant/lib/index.css'
import './styles/index.less'
import jquery from "jquery";
import md5 from 'js-md5';
Vue.prototype.$md5 = md5;
Vue.prototype.$qs = qs
Vue.prototype.$Cookies = Cookies
Vue.prototype.$axios = axios
Vue.prototype.$JSONBig = JSONBig
Vue.prototype.$ = jquery;
Vue.config.productionTip = false
Vue.use(Vant);
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
